import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import '../assets/stylesheets/index.scss';

export const siteQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
        keywords
        description
        ogDescription
        tdsPath
        tmPath
      }
    }
  }
`;

// ******** RC1 ******** 
//<script src={`${baseURL}/scripts/th-element-loader.js`}></script>
//<script src={`${baseURL}/modules/tm.js`} type="module"></script>
//<script src={`${baseURL}/modules/tds.js`} type="module"></script>


// ******** STAGING ******** 
//<script src={`${baseURL2}/modules/tds.js`} type="module"></script>
//<script src={`${baseURL2}/scripts/th-element-loader.js`}></script>
//<script src={`${baseURL2}/modules/tm.js`} type="module"></script>

const Layout = ({ children }) => {

  return (
    <StaticQuery
      query={siteQuery}
      render={({ site: { siteMetadata } }) => (
        <>
          {/* prettier-ignore */}
          <Helmet defaultTitle={siteMetadata.title}>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta charset="utf-8" />
            <meta name="description" content={siteMetadata.description} />
            <meta name="keywords" content={siteMetadata.keywords} />

            <link rel="stylesheet" media="all" href="https://developer.salesforce.com/resources2/fonts/Nexa/Nexa.css"/>  
            <link rel="stylesheet" media="all" href="https://developer.salesforce.com/resources2/fonts/trailheadBold/TrailheadBold.css"/>      
            <link rel="stylesheet" media="all" href="https://developer.salesforce.com/resources2/fonts/trailheadMedium/TrailheadMedium.css"/>  
            <link rel="stylesheet" media="all" href="https://developer.salesforce.com/resources2/fonts/salesforceSans/SalesforceSans.css"/>    
            <link rel="stylesheet" media="all" href="https://developer.salesforce.com/resources2/fonts/NeutrafaceDisplay-Titling/NeutrafaceDisplay-Titling.css"/> 

            <script src={`${siteMetadata.tdsPath}/scripts/th-element-loader.js`}></script>
            <script src={`${siteMetadata.tmPath}/modules/tm.js`} type="module"></script>
            <script src={`${siteMetadata.tdsPath}/modules/tds.js`} type="module"></script>
            <link href={`${siteMetadata.tdsPath}/css/tds-theme-provider.css`} rel="stylesheet" />
            <link href={`${siteMetadata.tdsPath}/css/tds.css`} rel="stylesheet" />

            <meta property="og:type" content="article" />
            <meta property="og:title" content={siteMetadata.title} />
            <meta property="og:description" content={siteMetadata.ogDescription} />
            <link rel="canonical" href="https://trailhead.salesforce.com/mvp" />
            {
              // <link rel="stylesheet" media="all" href="https://developer.salesforce.com/resources2/th-academy/css/salesforce-lightning-design-system.min.css"/>
              // iframeListener()
            }
          </Helmet>
          <div id="strtr-site" className="strtr-site">
            {children}
          </div>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
