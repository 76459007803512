import React from "react"
import PropTypes from 'prop-types'

const Card = (props) => {
    const { 
        data: {
            profileHref, 
            photoURL, 
            location, 
            firstName, 
            lastName, 
            fullName, 
            twitterLink, 
            twitterText, 
            linkedinLink, 
            linkedinText, 
            role, 
            cardRole
        }
    } = props

    const profileLink = linkedinLink !== null && linkedinLink !== '' ? linkedinLink : twitterLink;
    const profileText = linkedinLink !== null && linkedinLink !== '' ? linkedinText : twitterText;

    return (
        <tm-trailblazer-item
            key={`mvp-card-${fullName}`}
            cta-href={profileHref} 
            cta-title="View Profile" 
            img-src={photoURL} 
            img-alt="" 
            location={location} 
            name={`${fullName}`} 
            profile-href={profileLink} 
            profile-title={profileText} 
            rank={role} 
            role={cardRole} />
      )
}

Card.propTypes = {
    data: PropTypes.object.isRequired
}

export default Card